

* {
  text-align: center;
  font-family: 'Sora', sans-serif;
  user-select: none;
  padding: 0;
  margin: 0;
}


/* Classe para desativar o scroll */

.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(5px);
}
