header{

  background-color: #5D925D;
  
  padding: 10px;
  color: white;
}

body {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("./../public/stadium.png");
  background-size: cover;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden !important; /* Garante que o scroll seja desativado */
}

.App-header h2{
  font-family: monospace;
}
.moldura_top{
  background-image: url('./../public/moldura_top.png');
  
  max-width: 570px;
  width: 100%;
  max-height: 80px;
  height: 13vw;
  aspect-ratio: 80/570;
  color: white;
  display: flex;
  align-items: center;
  font-size: clamp(8px, 2vw, 12px);
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.canva_inset{
  inset: 0;
  top: -20px;
  width: 100%;

}
.moldura_bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 570px;   
  width: 100%;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("./../public/moldurabottom.png");
}

.call_to_action{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: clamp(12px, 1vw, 16px);
  font-weight: 600;
  border-radius: 6px;
  padding: 1em 0.7em;
  background: linear-gradient(to left, #1C2C1C, #5D925D 100%, #1C2C1C);

}


@media (width <= 630px) {
  .container::after, .container::before{
    display: none;
  }
}


@media screen and (max-width: 480px){
  

  .container {
    overflow-y: auto; /* Permite rolagem vertical dentro da container, se necessário */
    overflow-x: hidden;
  }
}

.moldura{
  background-image: url('./../public/moldura.png');
  background-position: center;
  max-width: 570px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 465px;
  min-width: 480px;
  height: 80vw;
  overflow: hidden;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: contain;
  overflow-y: hidden;
}


.moldura::after{
  content: "";
  background-image: url("./../public/bola_movimento.png");
  width: 160px;
  height: 160px;
  background-size: cover;
  position: absolute;
  z-index: -1;
  top: -150px;
  left: 60px;
}
.moldura::before{
  content: "";
  background-image: url("./../public/bask_ball.png");
  width: 200px;
  height: 200px;
  background-size: cover;
  position: absolute;
  z-index: -1;
  top: -150px;
  right: 60px;
}

.container::after{
  content: '';
  height: 90vh;
  width: 500px;
  z-index: -1;
  background-image: url('./../public/olmo.png');
  position: absolute;
}
.container::before{
  content: '';
  height: 120vh;
  width: 700px;
  z-index: -1;
  right: 15vw;
  background-image: url('./../public/baska.png');
  position: absolute;
}
.container::after{
  content: '';
  height: 90vh;
  width: 500px;
  z-index: -1;
  left: 20vw;
  background-image: url('./../public/olmo.png');
  position: absolute;
}
.container{
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100vw;
  padding-top: 20px;
}