.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}



.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  border-radius: 24px;
  overflow: hidden; /* Garante que nada fique fora dos limites do grid */
  max-width: 80%; /* Ajusta para não exceder a largura da tela */
}

.grid img {
  width: 100%;
  height: auto; /* Ajusta para manter a proporção da imagem */
  object-fit: contain;
  border-radius: 8px; /* Adiciona bordas arredondadas às imagens */
}

.divGeral {
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100vh; /* Ocupa toda a largura da tela */
  height: 100vh; /* Ocupa toda a altura da tela */
  position: relative; /* Alterado para relative para posicionamento correto */
  text-align: center;
  overflow: hidden; /* Esconde qualquer conteúdo que vá além dos limites da tela */
}

@media screen and (max-width: 480px) {

  .divGeral {
    width: 70%;
  }

  .no-scroll {
    overflow: hidden !important; /* Garante que o scroll seja desativado */
  }

}

.canva_inset {
  width: 100%; /* Garante que o canvas ocupe toda a área disponível */
  height: 100%; /* Mantém o canvas na altura total */
  position: absolute; /* Absoluto para ficar sobre as imagens */
  top: 0;
  left: 0;
  z-index: 3; /* Garante que o canvas esteja sobre as imagens */
  pointer-events: auto; 
}
