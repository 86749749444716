.modal_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60vw;
  max-width: 700px;
  height: auto;
  border-radius: 16px;
  padding: 1.5em 2em;
  background-color: #5D925D;
  gap: 1em;
}

.modal_box .parabens {
  width: 70%;
}

.modal_box h2 {
  color: white;
  font-weight: 200;
  font-size: 16px;
}



.rodadasGratis {
  font-size: clamp(12px, 2vw, 20px);
  font-weight: 200;
  color: white;
  width: 90%;
  padding: 4px;
  border: 1px solid #fff;
  border-radius: 8px;
}

.boldDobra {
  font-weight: 500;
}

a {
  text-decoration: none;
}


@media screen and (max-width: 480px) {
  
  .modal_box h2 {
    font-size: 14px;
  }

  .modal_box .parabens {
    width: 60%;
  }

  .modal_box {
    gap: 0.8em;
  }

}